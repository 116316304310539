import { Suspense } from "react"
import Landing from "./pages/Landing.jsx";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoadingPage from "./components/Loading/loading"

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <BrowserRouter className='App'>
        <Routes>
          <Route exact path='/' element={<Landing />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
