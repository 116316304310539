import React, { Fragment } from "react";
import commstyle from '../assets/css/module/common.module.css';

export default function Header(props) {
  return (
    <Fragment>
      <section id="team" className={`${commstyle['row']} ${commstyle['sec-team']} ${commstyle['section-bg']}`} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['section-title']}`}>
            <h2>Team</h2>
          </div>
          <div className={`${commstyle['row']} ${commstyle['team-desc']} `}>
            <p>
              PT. Jasa Dinamika Mandiri (JDM) has sharpened its profile by designing a sales system that will make it
              easier for our customers to find the right and best partners in our growing organization.
              By collaborating with high-tech companies from around the world and strengths, JDM has signed exclusive
              dealer and agent agreements for the Indonesian market. Our team consists of Indonesian experts with experience and qualified background in their respective fields. Our team has received strict training with safety and quality standards, and has received certification from the relevant agencies.
            </p>
          </div>
        </div>

      </section>
    </Fragment>
  );

}