import React, { Fragment, useEffect, useState } from "react";
import ArrowUp from '../assets/svg/ArrowUp.jsx';
import commstyle from '../assets/css/module/common.module.css';
import Logo from '../assets/img/logo-jdm.png';
import { Link } from "react-scroll";
import Sidebar from "../components/Nav/Sidebar";
import { RiMenuFill } from 'react-icons/ri';

export default function Header(props) {
  const [isVisible, setIsVisible] = useState(false);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (

    <Fragment>
      {
        isVisible ? <div className={commstyle['back-top']} onClick={scrollToTop}>
          <ArrowUp onClick={scrollToTop} />
        </div> : null
      }
      <header id="header" className={`${commstyle['fixed-top']} ${commstyle['row']}`}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['row-no-wrap']} ${commstyle['flx-v-c']} `}>
            <Link to="hero" spy={true} smooth={true} >
              <img className={`${commstyle['logo']}`} src={Logo} alt="" />
            </Link>
            {
              sidebarOpen? <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />:null
            }
            <nav id="navbar" className={`${commstyle['navbar']}`}>
              <ul>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-top-menu']}`} to="hero" spy={true} smooth={true} >
                    Home
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-top-menu']} `} to="about" spy={true} smooth={true} >
                    About
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-top-menu']}`} to="services" spy={true} smooth={true} >
                    Services
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-top-menu']} `} to="portfolio" spy={true} smooth={true} >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-top-menu']}`} to="team" spy={true} smooth={true}>
                    Team
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-top-menu']} `} to="contact" spy={true} smooth={true} >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${commstyle['getstarted']}`}
                    to="about" spy={true} smooth={true} >
                    Get Started
                  </Link>
                </li>
              </ul>
              <i
                className={`${commstyle['mobile-nav-toggle']}`}
              ><RiMenuFill onClick={() => toggleSidebar(!sidebarOpen)}/></i>
            </nav>
          </div>
        </div>
      </header>
    </Fragment>
  );
}