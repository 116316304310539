import React, { Fragment } from "react";
import commstyle from '../assets/css/module/common.module.css';
import Logo from '../assets/img/logo-jdm.png';
import { RiArrowDropRightLine, RiTwitterFill, RiFacebookBoxFill, RiInstagramFill } from 'react-icons/ri';
import { Link } from "react-scroll";

export default function Footer(props) {
  return (
    <Fragment>
      <section id="footer" className={`${commstyle['row']} ${commstyle['sec-footer']} ${commstyle['section-bg']}`} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['row']}`}>
            <div className={`${commstyle['col-4']} ${commstyle['footer-info']} `}>
              <img className={`${commstyle['logo-footer']}`} src={Logo} alt="" />
              <br />
              <br />
              <strong>Head Office</strong><br />
              <p>Ruko Mutiara Taman Palem Blok C9 No.21, Cengkareng Timur, Cengkareng, Jakarta Barat - DKI Jakarta</p>
              <strong>Marketing Office</strong>
              <br />
              <p>Jalan Pantai Indah Selatan No.30 RT4/RW3, Kamal Muara, Kec. Penjaringan Utara, Jakarta Utara - DKI
                Jakarta</p>
              <strong>Phone</strong>
              <br />
              <div>+62 21 50112330</div>
              <strong>Email</strong><br />
              <div>jasa.dinamika.mandiri@gmail.com<br /></div>
            </div>
            <div className={`${commstyle['col-4']} ${commstyle['footer-info']}`}>
              <h4>Useful Links</h4>
              <div className={`${commstyle['footer-link-opt']}`}>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="hero" spy={true} smooth={true} > Home </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="about" spy={true} smooth={true} > About us </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="services" spy={true} smooth={true} > Services </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="portofolio" spy={true} smooth={true} > Portofolio </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="team" spy={true} smooth={true} >  Team </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="contact" spy={true} smooth={true} > Contacts </Link>
                </div>
              </div>
            </div>
            <div className={`${commstyle['col-4']} ${commstyle['footer-info']}`}>
              <h4>Our Services</h4>
              <div className={`${commstyle['footer-link-opt']}`}>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="services" spy={true} smooth={true} > UAV </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="services" spy={true} smooth={true} > Fire Patrol </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="services" spy={true} smooth={true} > Aero Sports Equipment </Link>
                </div>
                <div className={`${commstyle['link-opt']}`}>
                  <RiArrowDropRightLine className={`${commstyle['icon-opt']}`} />
                  <Link activeClass={`${commstyle['opt-active']}`} to="services" spy={true} smooth={true} > Aircraft Spare Parts </Link>
                </div>
              </div>
            </div>
            <div className={`${commstyle['col-4']} ${commstyle['footer-info']}`}>
              <h4>Our Social Networks</h4>
              <div>
                <RiTwitterFill className={commstyle['social-icon']}/>
                <RiFacebookBoxFill className={commstyle['social-icon']}/>
                <RiInstagramFill className={commstyle['social-icon']}/>
              </div>
            </div>
          </div>
        </div>
        <div className={`${commstyle['col-1']} ${commstyle['footer-copyright']}`}>
          Copyright &copy;{new Date().getFullYear()}<Link to={'hero'} ><strong><span> PT. Jasa Dinamika Mandiri</span></strong></Link>
        </div>
      </section>
    </Fragment>
  );
}