import React, { Fragment } from "react";
import commstyle from '../assets/css/module/common.module.css';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import img1 from '../assets/img/portfolio/bnpb/bnpb-1.jpg';
import img2 from '../assets/img/portfolio/bnpb/bnpb-2.jpeg';
import img3 from '../assets/img/portfolio/bnpb/bnpb-3.jpeg';
import img4 from '../assets/img/portfolio/bnpb/bnpb-4.jpeg';
import img5 from '../assets/img/portfolio/bnpb/bnpb-5.jpeg';

import img6 from '../assets/img/portfolio/drone/drone-1.jpg';
import img7 from '../assets/img/portfolio/drone/drone-2.jpeg';
import img8 from '../assets/img/portfolio/drone/drone-3.jpeg';
import img9 from '../assets/img/portfolio/drone/target-drone-1.jpg';
import img10 from '../assets/img/portfolio/drone/target-drone-2.jpeg';

import img11 from '../assets/img/portfolio/portfolio/portfolio-1.jpeg';
import img12 from '../assets/img/portfolio/portfolio/portfolio-2.jpeg';
import img13 from '../assets/img/portfolio/portfolio/portfolio-3.jpeg';


export default function Header(props) {
  const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13
  ]

  return (
    <Fragment>
      <section id="portfolio" className={`${commstyle['row']} ${commstyle['sec-portfolio']} `} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['section-title']}`}>
            <h2>Portfolio</h2>
          </div>
          <div className={`${commstyle['portofolio-gallery']}`}>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} >
              <Masonry columnsCount={3} gutter="30px">
                {images.map((image, i) => (
                  <img
                    className={`${commstyle['fade-in']}`}
                    key={i}
                    src={image}
                    style={{ width: "100%", display: "block" }}
                    alt={`img${i}`}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </section>
    </Fragment>
  );
}