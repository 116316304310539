import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.62706in" height="0.305358in" version="1.1"
    viewBox="0 0 388.75 72.96">
      <g id="Layer_x0020_1">
        <g id="_2629204176976">
          <polygon fill="#96989A" fillRule="nonzero" points="349.77,29.68 337.93,47.93 362.77,47.93 364.83,44.74 " />
          <polygon fill="#96989A" fillRule="nonzero" points="352.11,26.07 350.33,28.83 365.38,43.89 367.17,41.12 " />
          <polygon fill="#96989A" fillRule="nonzero" points="354.46,22.46 352.67,25.21 367.72,40.27 369.51,37.51 " />
          <path fill="#96989A" fillRule="nonzero" d="M265.68 0l-31.09 47.93 24.84 0 27.27 -42.03 26.74 0 -27.27 42.03 25.36 0 27.27 -42.03 26.4 0 -10.18 15.7 15.05 15.05 17.88 -27.63c0.23,-0.35 0.43,-1.01 0.61,-2 0.17,-0.98 0.23,-2 0.17,-3.04 -0.06,-1.04 -0.23,-1.97 -0.52,-2.78 -0.29,-0.81 -0.67,-1.22 -1.13,-1.22l-121.39 0z" />
          <path fill="#96989A" fillRule="nonzero" d="M205.39 33.75c-0.15,0.16 -0.3,0.31 -0.44,0.46 -7.2,7.38 -10.13,7.47 -16.41,7.47l-34.21 0 -3.99 6.25 53.49 0c4.63,0 8.91,-0.69 12.85,-2.08 0.2,-0.07 0.4,-0.14 0.6,-0.22l-11.88 -11.88z" />
          <path fill="#96989A" fillRule="nonzero" d="M208.11 30.52c-0.72,0.92 -1.4,1.75 -2.06,2.49l12.23 12.23c1.08,-0.43 2.14,-0.89 3.16,-1.39l-13.34 -13.34z" />
          <path fill="#96989A" fillRule="nonzero" d="M210.64 27.09c-0.66,0.94 -1.3,1.82 -1.91,2.63l13.66 13.66c1,-0.51 1.96,-1.06 2.9,-1.64l-14.65 -14.65z" />
          <path fill="#96989A" fillRule="nonzero" d="M141.82 0l-31.09 47.93 24.83 0 27.09 -41.68 49.67 0c2.2,0 3.71,0.58 4.52,1.74 0.81,1.16 1.13,2.58 0.96,4.25 -0.17,1.68 -0.67,3.47 -1.48,5.38 -0.81,1.91 -1.74,3.62 -2.78,5.12 -0.81,1.25 -1.58,2.42 -2.33,3.51l14.94 14.94c0.41,-0.27 0.81,-0.54 1.2,-0.82 3.18,-2.26 6.08,-4.86 8.68,-7.81 2.61,-2.95 4.95,-5.99 7.03,-9.12 1.85,-3.01 3.18,-5.93 3.99,-8.77 0.81,-2.84 0.93,-5.33 0.35,-7.47 -0.58,-2.14 -1.88,-3.88 -3.91,-5.21 -2.03,-1.33 -4.83,-2 -8.42,-2l-93.26 0z" />
          <polygon fill="#E30C0F" fillRule="nonzero" points="123.2,15.39 133.2,0 108.2,0 108.04,0.23 " />
          <polygon fill="#96989A" fillRule="nonzero" points="120.86,19 122.65,16.24 107.49,1.09 105.71,3.85 " />
          <polygon fill="#96989A" fillRule="nonzero" points="118.51,22.61 120.3,19.85 105.15,4.7 103.37,7.46 " />
          <path fill="#96989A" fillRule="nonzero" d="M106.11 41.68l11.84 -18.22 -15.14 -15.14 -21.71 33.54 -56.27 0 10.42 -16.15 -24.83 0 -10.42 16.15c0.23,1.16 0.43,2.29 0.61,3.39 0.17,1.1 0.9,2 2.17,2.69l93.95 0c1.74,-1.04 3.41,-2.08 5.04,-3.13 1.62,-1.04 3.07,-2.08 4.34,-3.13z" />
          <polygon fill="#E30C0F" points="1.26,54.91 362.69,54.91 362.69,55.58 351.77,72.96 1.26,72.96 " />
          <path fill="#FEFEFE" fillRule="nonzero" d="M37.03 68.88m8.57 -9.76c-1.6,0 -3.19,0 -4.78,0l0 1.71 2.94 0 0 3.89c0,1.48 -0.78,2.63 -2.45,2.63 -1.66,0 -2.47,-0.85 -2.47,-2.52l-1.81 0c0,2.76 1.78,4.25 4.28,4.25 2.69,0 4.29,-1.84 4.29,-4.36l0 -5.6z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M53.57 68.88m5.77 -5.92c-0.39,-0.68 -1.27,-1.11 -2.19,-1.11 -2.01,-0.01 -3.58,1.23 -3.58,3.59 0,2.41 1.5,3.66 3.54,3.65 0.77,-0.01 1.84,-0.4 2.23,-1.21l0.08 1 1.6 0 0 -6.87 -1.63 0 -0.06 0.95zm-2.03 0.4c2.68,0 2.68,4.17 0,4.17 -1.13,0 -2.03,-0.78 -2.03,-2.09 0,-1.31 0.91,-2.08 2.03,-2.08z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M69.11 68.88m5.81 -6.16c-0.79,-0.7 -1.6,-0.91 -2.67,-0.91 -1.25,0 -2.9,0.56 -2.9,2.15 0,1.56 1.55,2.03 2.84,2.13 0.97,0.06 1.36,0.25 1.36,0.72 0,0.5 -0.6,0.84 -1.24,0.82 -0.77,-0.01 -1.88,-0.42 -2.37,-0.96l-0.84 1.21c1,1.05 2.09,1.23 3.18,1.23 1.96,0 2.95,-1.05 2.95,-2.26 0,-1.82 -1.64,-2.12 -2.94,-2.2 -0.88,-0.06 -1.28,-0.31 -1.28,-0.75 0,-0.43 0.45,-0.68 1.25,-0.68 0.65,0 1.21,0.15 1.7,0.61l0.95 -1.11z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M82.98 68.88m5.77 -5.92c-0.39,-0.68 -1.27,-1.11 -2.19,-1.11 -2.01,-0.01 -3.58,1.23 -3.58,3.59 0,2.41 1.51,3.66 3.54,3.65 0.77,-0.01 1.84,-0.4 2.23,-1.21l0.08 1 1.6 0 0 -6.87 -1.63 0 -0.06 0.95zm-2.03 0.4c2.67,0 2.67,4.17 0,4.17 -1.13,0 -2.03,-0.78 -2.03,-2.09 0,-1.31 0.9,-2.08 2.03,-2.08z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M108.76 68.88m3.83 -9.76c-1.2,0 -2.63,0 -3.83,0l0 9.76c1.2,0 2.63,0 3.83,0 3.41,0 4.92,-2.49 4.88,-4.97 -0.04,-2.41 -1.53,-4.79 -4.88,-4.79zm-2.01 1.74l2.01 0c2.12,0 3.01,1.52 3.05,3.04 0.04,1.6 -0.84,3.22 -3.05,3.22l-2.01 0 0 -6.25z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M125.43 68.88m1.85 -6.9l0 6.9 -1.7 0 0 -6.9 1.7 0zm-1.85 -1.91c0,1.32 2.01,1.32 2.01,0 0,-1.32 -2.01,-1.32 -2.01,0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M135.81 68.88m6.8 0l0 -3.61c0,-2.16 -1.3,-3.37 -2.91,-3.37 -0.86,0 -1.56,0.35 -2.26,1.03l-0.11 -0.93 -1.52 0 0 6.88 1.69 0 0 -3.51c0,-1.05 0.71,-1.92 1.76,-1.92 1.09,0 1.66,0.79 1.66,1.84l0 3.59 1.7 0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M150.73 68.88m5.77 -5.92c-0.39,-0.68 -1.27,-1.11 -2.19,-1.11 -2.01,-0.01 -3.58,1.23 -3.58,3.59 0,2.41 1.5,3.66 3.54,3.65 0.77,-0.01 1.84,-0.4 2.23,-1.21l0.08 1 1.6 0 0 -6.87 -1.63 0 -0.06 0.95zm-2.03 0.4c2.68,0 2.68,4.17 0,4.17 -1.13,0 -2.03,-0.78 -2.03,-2.09 0,-1.31 0.91,-2.08 2.03,-2.08z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M166.65 68.88m6.27 0l0 -3.68c0,-0.91 0.57,-1.7 1.49,-1.7 0.92,0 1.3,0.77 1.3,1.67l0 3.71 1.7 0 0 -3.71c0,-2.06 -0.96,-3.26 -2.91,-3.26 -0.89,0 -1.69,0.28 -2.3,1.25 -0.39,-0.95 -1.21,-1.28 -2.03,-1.28 -0.65,0 -1.45,0.25 -1.81,0.95l-0.13 -0.84 -1.57 0 0 6.88 1.7 0 0 -3.68c0,-0.91 0.54,-1.73 1.46,-1.73 0.93,0 1.41,0.82 1.41,1.73l0 3.68 1.7 0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M186.01 68.88m1.85 -6.9l0 6.9 -1.7 0 0 -6.9 1.7 0zm-1.85 -1.91c0,1.32 2.01,1.32 2.01,0 0,-1.32 -2.01,-1.32 -2.01,0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M196.39 68.88m0 -9.74l0 9.74 1.7 0 0 -3.11 2.69 3.11 2.05 0 0 -0.13 -3.19 -3.51 2.8 -3.13 0 -0.1 -2.03 0 -2.31 2.76 0 -5.63 -1.7 0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M210.26 68.88m5.77 -5.92c-0.39,-0.68 -1.27,-1.11 -2.19,-1.11 -2.01,-0.01 -3.58,1.23 -3.58,3.59 0,2.41 1.5,3.66 3.54,3.65 0.77,-0.01 1.84,-0.4 2.23,-1.21l0.08 1 1.6 0 0 -6.87 -1.63 0 -0.06 0.95zm-2.03 0.4c2.67,0 2.67,4.17 0,4.17 -1.13,0 -2.03,-0.78 -2.03,-2.09 0,-1.31 0.9,-2.08 2.03,-2.08z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M236.17 68.88m8.48 0l1.84 0 0 -9.74 -2.1 0 -3.05 4.18 -3.05 -4.18 -2.12 0 0 9.74 1.84 0 0 -7.08 3.11 4.23 0.36 0 3.18 -4.22 0 7.06z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M254.89 68.88m5.77 -5.92c-0.39,-0.68 -1.27,-1.11 -2.19,-1.11 -2.01,-0.01 -3.58,1.23 -3.58,3.59 0,2.41 1.51,3.66 3.54,3.65 0.77,-0.01 1.84,-0.4 2.23,-1.21l0.08 1 1.6 0 0 -6.87 -1.63 0 -0.06 0.95zm-2.03 0.4c2.67,0 2.67,4.17 0,4.17 -1.13,0 -2.03,-0.78 -2.03,-2.09 0,-1.31 0.91,-2.08 2.03,-2.08z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M270.77 68.88m6.8 0l0 -3.61c0,-2.16 -1.3,-3.37 -2.91,-3.37 -0.86,0 -1.56,0.35 -2.26,1.03l-0.11 -0.93 -1.52 0 0 6.88 1.69 0 0 -3.51c0,-1.05 0.71,-1.92 1.76,-1.92 1.09,0 1.66,0.79 1.66,1.84l0 3.59 1.7 0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M285.75 68.88m3.65 -5.45c1.07,0 1.99,0.81 1.99,2.01 0,1.24 -0.92,2.02 -1.99,2.02 -1.09,0 -1.95,-0.82 -1.95,-2.02 0,-1.24 0.86,-2.01 1.95,-2.01zm2.1 -4.29l0 3.8c-0.4,-0.71 -1.53,-1.1 -2.26,-1.1 -2.01,0 -3.5,1.23 -3.5,3.59 0,2.26 1.52,3.59 3.54,3.59 0.84,0 1.68,-0.28 2.21,-1.1l0.11 0.95 1.59 0 0 -9.74 -1.7 0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M301.56 68.88m1.85 -6.9l0 6.9 -1.7 0 0 -6.9 1.7 0zm-1.85 -1.91c0,1.32 2.01,1.32 2.01,0 0,-1.32 -2.01,-1.32 -2.01,0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M311.98 68.88m0 -6.87l0 6.87 1.7 0 0 -3.62c0,-1.27 0.82,-1.74 1.71,-1.74 0.56,0 0.88,0.15 1.23,0.45l0.77 -1.48c-0.38,-0.38 -1.06,-0.65 -1.77,-0.65 -0.7,0 -1.41,0.13 -1.94,0.98l-0.13 -0.79 -1.57 0z" />
          <path fill="#FEFEFE" fillRule="nonzero" d="M324.9 68.88m1.85 -6.9l0 6.9 -1.7 0 0 -6.9 1.7 0zm-1.85 -1.91c0,1.32 2.01,1.32 2.01,0 0,-1.32 -2.01,-1.32 -2.01,0z" />
        </g>
      </g>
      {/* <g>
        <path fill="white" fillRule="nonzero" d="M-0 168.7l0 -82.69 95.1 -86 95.1 86 0 82.69 -95.1 -86 -95.1 86zm0 -41.35m47.55 -84.35m95.1 0m47.55 84.35m-47.55 -1.65m-95.1 0" />
      </g> */}
    </svg>

  );
}

export default SvgComponent;
