import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0.367976in" height="0.326378in" version="1.1"
      viewBox="0 0 190.2 168.7">
      <g>
        <path fill="white" fillRule="nonzero" d="M-0 168.7l0 -82.69 95.1 -86 95.1 86 0 82.69 -95.1 -86 -95.1 86zm0 -41.35m47.55 -84.35m95.1 0m47.55 84.35m-47.55 -1.65m-95.1 0" />
      </g>
    </svg>

  );
}

export default SvgComponent;
