import React, { Fragment, useRef, useEffect, useState } from "react";

import Header from '../components/Header';
import Hero from '../components/Hero';
import FAQ from '../components/FAQ';
import Clients from '../components/Clients';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Portofolio from '../components/Portofolio';
import Team from '../components/Team';
import commstyle from '../assets/css/module/common.module.css';

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    let cr = domRef.current
    observer.observe(cr);
    return () => observer.unobserve(cr);
  }, []);
  return (
    <div
      className={`${commstyle['fade-in-section']} ${isVisible ? commstyle['is-visible'] : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}


export default function Landing() {

  let home = useRef();
  let client = useRef();
  let about = useRef();
  let services = useRef();
  let faq = useRef();
  let portofolio = useRef();
  let team = useRef();
  let contact = useRef();
  return (
    <Fragment>
      <div>
        <Header listRef={[home, client, about, services, faq, portofolio, team, contact]} />
        <Hero uref={home} />
        <main id="main">
          <FadeInSection>
            <Clients uref={client} />
          </FadeInSection>
          <FadeInSection>
            <AboutUs uref={about} />
          </FadeInSection>
          <FadeInSection>
            <Services uref={services} />
          </FadeInSection>
          <FadeInSection>
            <FAQ uref={faq} />
          </FadeInSection>
          <FadeInSection>
            <Portofolio uref={portofolio} />
          </FadeInSection>
          <FadeInSection>
            <Team uref={team} />
          </FadeInSection>
          <FadeInSection>
            <Contact uref={contact} />
          </FadeInSection>
        </main>
        <FadeInSection>
          <Footer />
        </FadeInSection>
      </div>
    </Fragment>
  );
}
