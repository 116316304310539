import React, { Fragment} from "react";
import commstyle from '../assets/css/module/common.module.css';
import { Link } from "react-scroll";
import img1 from '../assets/img/hero-img.png'

export default function Header(props) {
  return (
    <Fragment>
      <section id="hero" className={`${commstyle['row']} ${commstyle['sec-home']} `} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['row']} ${commstyle['flx-v-c']} ${commstyle['hero']}`}>
            <div className={`${commstyle['col-2']} ${commstyle['flx']} ${commstyle['flx-h-c']} ${commstyle['flx-dr-cl']} ${commstyle['fadeInUp']}`}>
              <h1>PT. JASA DINAMIKA MANDIRI</h1>
              <h2>Better Solutions For Your Business</h2>
              <h5>UAV, Mapping Surveillance and Paragliding/Paramotor Equipment</h5>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <Link
                  className={`${commstyle['btn-get-started']}`}
                  to="about" spy={true} smooth={true} >
                  Get Started
                </Link>
              </div>
            </div>
            <div className={`${commstyle['col-2']} ${commstyle['flx']} ${commstyle['flx-h-c']}`}>
              <img src={img1} className={`${commstyle['img-fluid']} ${commstyle['img-in']} ${commstyle['animated']} `} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}