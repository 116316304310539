import React, { Fragment } from "react";
import CL1 from '../assets/img/clients/smartaviation.png';
import CL2 from '../assets/img/clients/simplify.png';
import CL3 from '../assets/img/clients/penerbad.png';
import CL4 from '../assets/img/clients/itv.png';
import CL5 from '../assets/img/clients/BNPB.png';
import CL6 from '../assets/img/clients/aeromapping.png';
import CL7 from '../assets/img/clients/client1.png';
import commstyle from '../assets/css/module/common.module.css';

export default function Header(props) {
  return (
    <Fragment>
      <section id="clients" className={`${commstyle['row']} ${commstyle['section-bg']}`} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['row']} ${commstyle['flx-v-c']} `}>
            <div className={`${commstyle['client-icon']}`}>
              <img src={CL1} className={`${commstyle['client-img']}`} alt="" title="Smartaviation" />
            </div>

            <div className={`${commstyle['client-icon']}`} >
              <img src={CL2} className={`${commstyle['client-img']}`} alt="" title="Simplify" />
            </div>

            <div className={`${commstyle['client-icon']}`} >
              <img src={CL3} className={`${commstyle['client-img']}`} alt="" title="Penerbad" />
            </div>

            <div className={`${commstyle['client-icon']}`} >
              <img src={CL4} className={`${commstyle['client-img']}`} alt="" title="ITV" />
            </div>

            <div className={`${commstyle['client-icon']}`} >
              <img src={CL5} className={`${commstyle['client-img']}`} alt="" title="BNPB" />
            </div>

            <div className={`${commstyle['client-icon']}`} >
              <img src={CL6} className={`${commstyle['client-img']}`} alt="" title="Aeromapping" />
            </div>
            <div className={`${commstyle['client-icon']}`} >
              <img src={CL7} className={`${commstyle['client-img']}`} alt="" title="General Dinamik" />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );

}