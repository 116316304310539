import React, { Fragment, useState } from "react";
import commstyle from '../assets/css/module/common.module.css';
import { Accordion, AccordionContainer } from "./Accordion/Accordion";

export default function Header(props) {
  const [activeAcc, setActiveAcc] = useState('');
  return (
    <Fragment>
      <section id="faq" className={`${commstyle['row']} ${commstyle['sec-faq']} ${commstyle['section-bg']}`} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['section-title']}`}>
            <h2>Our Offer</h2>
          </div>
          <div className={`${commstyle['row']}`}>
            <div className={`${commstyle['col-1']} ${commstyle['faq-list']}`}>
              <AccordionContainer>
                <Accordion
                  title='Professionalism'
                  desc='Is the main thing for JDM. We serve consumers in a professional manner to increase consumer confidence'
                  name='acc1'
                  act={activeAcc}
                  setActive={setActiveAcc}
                />
                <Accordion
                  title='Best Service'
                  desc=' JDM will continue to develop in terms of service, always evaluating and improving to serve consumers well.'
                  name='acc2'
                  act={activeAcc}
                  setActive={setActiveAcc}
                />
                <Accordion
                  title='Trusted'
                  desc='JDM also maintains the trust that has been given to us by providing the best service.'
                  name='acc3'
                  act={activeAcc}
                  setActive={setActiveAcc}
                />
              </AccordionContainer>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );

}