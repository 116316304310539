import React, { Fragment } from "react";
import commstyle from '../assets/css/module/common.module.css';

export default function Services(props) {
  return (
    <Fragment>
      <section id="services" className={`${commstyle['row']} ${commstyle['services']} ${commstyle['section-bg']}`} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['section-title']}`}>
            <h2>Services</h2>
          </div>
          <div className={`${commstyle['row']}`}>
            <div className={`${commstyle['col-4']} ${commstyle['svc-box']}`}>
              <h4>Unmanned Aerial Vehicle</h4>
              <p>Target Drone , Agricultural Automatic Spray Drone, Mapping & Surveillance </p>
            </div>
            <div className={`${commstyle['col-4']} ${commstyle['svc-box']}`}>
              <h4>Fire Patrol</h4>
              <p>Forest and Land Fire Air Patrol</p>
            </div>
            <div className={`${commstyle['col-4']} ${commstyle['svc-box']}`}>
              <h4>Aero Sports Equipment</h4>
              <p>Aeromodelling, Paragliding & Paramotor Equipment</p>
            </div>
            <div className={`${commstyle['col-4']} ${commstyle['svc-box']}`}>
              <h4>Aircraft Spare Parts</h4>
              <p>Spare Parts</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}