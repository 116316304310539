import React, { Fragment } from "react";
import commstyle from '../assets/css/module/common.module.css';
import { RiMapPin2Line, RiMailLine, RiSmartphoneLine } from 'react-icons/ri';

export default function Contact(props) {
  return (
    <Fragment>
      <section id="contact" className={`${commstyle['row']} ${commstyle['sec-contact']} `} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['section-title']}`}>
            <h2>Contact US</h2>
          </div>
          <div className={`${commstyle['row']}`}>
            <div className={`${commstyle['col-40']} ${commstyle['contact-info']}`}>
              <div className={`${commstyle['row-no-wrap']} ${commstyle['contact-detail']}`}>
                <div className={commstyle['icon-contact']}>
                  <RiMapPin2Line />
                </div>
                <div className={commstyle['contact-sub-detail']}>
                  <h4>Location:</h4>
                  <h6>Head Office</h6>
                  <p>Ruko Mutiara Taman Palem Blok C9 No.21, Cengkareng Timur, Cengkareng, Jakarta Barat - DKI Jakarta</p>
                  <h6>Marketing Office</h6>
                  <p>Jalan Pantai Indah Selatan No.30 RT4/RW3, Kamal Muara, Kec. Penjaringan Utara, Jakarta Utara - DKI
                    Jakarta</p>
                </div>
              </div>
              <div className={`${commstyle['row-no-wrap']} ${commstyle['contact-detail']} `}>
                <div className={commstyle['icon-contact']}>
                  <RiMailLine />
                </div>
                <div className={commstyle['contact-sub-detail']}>
                  <h4>Email:</h4>
                  <p>jasa.dinamika.mandiri@gmail.com</p>
                </div>
              </div>
              <div className={`${commstyle['row-no-wrap']} ${commstyle['contact-detail']} `}>
                <div className={commstyle['icon-contact']}>
                  <RiSmartphoneLine />
                </div>
                <div className={commstyle['contact-sub-detail']}>
                  <h4>Call:</h4>
                  <h6>Head Office</h6>
                  <p>+62 21 50112330</p>
                </div>
              </div>
            </div>
            <div className={`${commstyle['col-60']} ${commstyle['contact-info']}`}>
              <iframe
                title="gmaps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.7615112549078!2d106.74926782918858!3d-6.124505999722841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x58f917bb569eeda7!2zNsKwMDcnMjguMiJTIDEwNsKwNDQnNTkuMyJF!5e0!3m2!1sen!2sid!4v1660018805011!5m2!1sen!2sid"
                className={commstyle['contact-map']}
                allowFullScreen
              >
              </iframe>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}