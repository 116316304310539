import React, { Fragment } from "react";
import commstyle from '../assets/css/module/common.module.css';

export default function AboutUs(props) {
  return (
    <Fragment>
      <section id="about" className={`${commstyle['row']} ${commstyle['sec-about']}`} ref={props.uref}>
        <div className={`${commstyle['col-80']}`}>
          <div className={`${commstyle['section-title']}`}>
            <h2>About Us</h2>
          </div>
          <div className={`${commstyle['row']}`}>
            <div className={`${commstyle['col-2']} ${commstyle['about']}`}>
              <h3>
                PT. Jasa Dinamika Mandiri
              </h3>
              <p className={`${commstyle['txt-jf']}`}>
                PT. Jasa Dinamika Mandiri (JDM) was established in Jakarta based on the Notary Deed of
                Shella Falianti,S.H., No.05. - February 15,2021.
                The Company commenced its commercial activities in June 2021.
              </p>
              <p className={`${commstyle['txt-jf']}`}>
                PT. Jasa Dinamika Mandiri (JDM) is a limited liability company engaged in the procurement and service of
                aviation
                services which includes as a distributor of aircraft tools and equipment, ground handling, aircraft rental
                and maintenance,
                aero sports, surveys and air patrols for civil and military needs.
              </p>
              <p className={`${commstyle['txt-jf']}`}>
                JDM is a company designed for the development of business services in the field of providing goods and
                services.
                Although classified as a private company that is still very young, we are supported by competent and
                certified experts who also have long work experience in the aviation sector.
              </p>
            </div>
            <div className={`${commstyle['col-2']} ${commstyle['about']}`}>
              <h3>
                Vision & Mission
              </h3>
              <p className={`${commstyle['txt-jf']}`}>
                PT. Jasa Dinamika Mandiri (JDM) has embarked on a challenging program to secure the company's future by
                expanding its portofolio as a service centre and supplier of high-quality aviation products into offerings
                for aircraft and non-aviation products that promote high-tech cutting-edge products for the Indonesian
                market.
              </p>
              <p className={`${commstyle['txt-jf']}`}>
                Long-Term planning, additional investment in new products, customer service through better service, highly
                skilled and highly motivated employees, supported by experienced overseas business partners, are very
                important for the future development of the company.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>

  );

}