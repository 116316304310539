import React, { Fragment } from "react";
import { Link } from "react-scroll";
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";
import commstyle from '../../assets/css/module/common.module.css';

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Fragment>
      <div className={`${commstyle['row']} ${commstyle['sidebar']} `}>
        <div className={`${commstyle['col-1']}`}>
          <div className={`${commstyle['row']} ${commstyle['sidebar-top']} `}>
            <div className={`${commstyle['logo-sidebar']}`}>
              <LogoIcon />
            </div>
            <CloseIcon className={`${commstyle['close-icon-sidebar']}`} onClick={()=>toggleSidebar() }/>
          </div>
          <div className={`${commstyle['row']} `}>
            <nav id="navbar" className={`${commstyle['nav-sidebar']}`}>
              <ul>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-sidebar-menu']}`} to="hero" spy={true} smooth={true} onClick={()=>toggleSidebar()}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-sidebar-menu']} `} to="about" spy={true} smooth={true} onClick={()=>toggleSidebar()}>
                    About
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-sidebar-menu']}`} to="services" spy={true} smooth={true} onClick={()=>toggleSidebar()}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-sidebar-menu']} `} to="portfolio" spy={true} smooth={true} onClick={()=>toggleSidebar()}>
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-sidebar-menu']}`} to="team" spy={true} smooth={true} onClick={()=>toggleSidebar()}>
                    Team
                  </Link>
                </li>
                <li>
                  <Link activeClass={`${commstyle['menu-active']}`} className={`${commstyle['nav-sidebar-menu']} `} to="contact" spy={true} smooth={true} onClick={()=>toggleSidebar()}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
