import React, { useRef, useState } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri';

import commstyle from '../../assets/css/module/common.module.css'

const AccordionContainer = ({ children }) => {
  return (
    <div className={commstyle['acc-container']}>{children}</div>
  )
}

const Accordion = ({ title, desc, name, act, setActive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentHeight = useRef()
  const handleItemClick = (x) => {
    let tmpOpen = !isOpen
    if (tmpOpen) {
      setActive(x)
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={commstyle['acc-wrapper']} >
      <button className={`${commstyle['question-container']} ${isOpen && act === name ? commstyle['active'] : ''}`} onClick={() => handleItemClick(name)} >
        <p className={commstyle['question-content']}>{title}</p>
        <RiArrowDropDownLine className={`${commstyle['arrow']} ${isOpen && act === name ? commstyle['active'] : ''}`} />
      </button>

      <div ref={contentHeight} className={commstyle['answer-container']} style={isOpen && act === name ? { height: '100%' } : { height: "0px" }}>
        {
          isOpen && act === name ?
            <div>
              <p className={commstyle['answer-content']}>{desc} </p>
            </div> : null
        }
      </div>
    </div>
  )
}

export { Accordion, AccordionContainer };
